import './App.css';

function App() {
  return (
    <div className="App">
      <p>
        Distorted Visuals ®
      </p>
      <h1>
        Delivering Messages,<br />
        Crafting Digital Experience.
      </h1>
      <p>
        (Vienna, Austria) (Full website soon)
      </p>
      <p>
        Kajetan Powolny
        Zwinzstraße 5/1/14
        1160 Wien
        Telefonnummer: 0699 19211400
        E-Mail: hello[@]distortedvisuals.studio
        Webseite: distortedvisuals.studio
        UID-Nr: ATU76524646
        GISA-Zahl: 31498906
        Mitgliedschaften bei der Wirtschaftskammerorganisation: Mitglied der WKÖ, WKNÖ
        Gewerbeordnung: www.ris.bka.gv.at, Freies Gewerbe Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.

        Bei Fragen schreiben Sie mir doch einfach eine E-Mail an hello[@]distortedvisuals.studio. Ich nehmen mir gerne die Zeit um alles zu beantworten. Falls es noch immer Unklarheiten gibt, können wir gerne telefonieren.
      </p>
    </div>
  );
}

export default App;
